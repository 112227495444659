import "./upsellPage.scss";

import { lazy, Suspense, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PresentationType } from "@components/Cart";
import { PageConsultation } from "@components/layouts/PageConsultation";
import Header from "@elements/Header";
import { useTitle } from "@hooks/useTitle";
import { AlertBanner, Button, Title } from "@runwayhealth/runway-components-react";
import { UpsellConditions } from "@store/../@types/case";
import { Condition } from "@store/../@types/condition";
import { RootState } from "@store/../@types/state";
import { addConditions } from "@store/cases/casesSlice";

// Lazy components
const Cart = lazy(() => import("@components/Cart/index"));

const UpsellPage = () => {
  useTitle("Recommended Meds");
  const history = useHistory();
  const dispatch = useDispatch();
  const items = useSelector<RootState, string[]>((state) =>
    state.case.newCase.data.conditions.map((condition) => condition.product.name)
  );
  const [addonConditions, setAddonConditions] = useState<Condition[]>([]);

  const upsellData = useSelector<RootState, UpsellConditions>(
    (state) =>
      state.case.newCase.data.upsell ?? {
        conditions: [],
      }
  );

  const nextStep = () => {
    // Update questionnaire by updating consultation session attribute.
    const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
    const newConsultations = addonConditions.map((condition) => condition.product.name);
    sessionStorage.setItem(
      "consultations",
      JSON.stringify([...consultations, ...newConsultations])
    );

    dispatch(addConditions(addonConditions));
    history.push("/departure");
  };

  return (
    <>
      <Header progress={4} />
      <PageConsultation>
        <PageConsultation.Header>
          <Title size="giant">
            Runway offers {items.length > 0 ? "additional" : ""} medications that are commonly
            prescribed to travelers visiting your destination.
          </Title>
        </PageConsultation.Header>

        <PageConsultation.Body>
          <div className="upsell-page-body">
            <div className="wrapper">
              <Suspense fallback={<div>Loading...</div>}>
                <Cart
                  items={items}
                  country={""}
                  presentationType={PresentationType.UPSELL}
                  upsellConditions={upsellData.conditions}
                  addonConditions={addonConditions}
                  setAddonConditions={setAddonConditions}
                />
              </Suspense>
              <AlertBanner variant="info" className="warning-banner" isRounded>
                <AlertBanner.Message>
                  {upsellData.reason ??
                    "Many travelers also request these medications when heading to your destination. Adding them to your order now is a great way to ensure you're fully prepared and stress-free for your upcoming trip."}
                </AlertBanner.Message>
              </AlertBanner>
              <div className="btn-container">
                <Button size="lg" iconName="ArrowRight" customWidth={440} onClick={nextStep}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        </PageConsultation.Body>
      </PageConsultation>
    </>
  );
};

export default UpsellPage;
