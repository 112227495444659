import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  AddressBody,
  AddressContainer,
  ConfirmButton,
  ConfirmButtonWrapper,
  DefaultTag,
  EditDeleteButtonsContainer,
} from "@components/elements/ShippingAddress";
import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { Button, Text, Title } from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import { RootEntity } from "@store/../@types/state";
import { Address, User } from "@store/../@types/user";
import * as UserActions from "@store/user/userActions";

import AddressForm from "./components/AddressForm";
import { addressInitializerObject } from "./components/AddressForm/formSchema";

type AddressManagerProps = {
  user: RootEntity<User>;
  enableCardDecor?: boolean;
  openForm?: boolean;
  isCheckout?: boolean;
  confirmSelectedAddress?: (addressId: string) => void;
  newCase?: RootEntity<Case>;
  closeManager?: () => void;
  pendingAddressConfirmation?: boolean;
};

const AddressManager = ({
  user,
  enableCardDecor,
  openForm,
  isCheckout,
  confirmSelectedAddress,
  newCase,
  closeManager,
  pendingAddressConfirmation,
}: AddressManagerProps) => {
  const [formIsOpen, setFormIsOpen] = useState(openForm);
  const dispatch = useDispatch();
  const [addressToEdit, setAddressToEdit] = useState<Address>(addressInitializerObject);

  const [selectedAddress, setSelectedAddress] = useState<string | undefined>(
    newCase?.data?.shippingAddress?.id ??
      user.data?.addresses?.filter((address) => address.default)[0]?.id ??
      undefined
  );

  const { reportPurchasedProducts: reportShippingInfo } = useDataLayer(GA4Events.ADD_SHIPPING_INFO);

  useEffect(() => {
    dispatch({
      type: UserActions.GET_ADDRESSES,
      userId: user.data.id,
    });
  }, []);

  useEffect(() => {
    if (user.data.addresses?.length === 1) {
      setSelectedAddress(user.data.addresses[0].id);
    }
  }, [user.data.addresses]);

  const saveAddress = (data: Address) => {
    const address = {
      ...data,
      country: "us",
    };

    // Collect analytics.
    // Report add shipping info event.
    if (newCase?.data.id) {
      reportShippingInfo();
    }

    if (address.id) {
      dispatch({
        type: UserActions.UPDATE_ADDRESS,
        payload: address,
      });
    } else {
      dispatch({
        type: UserActions.CREATE_ADDRESS,
        address,
        userId: user.data.id,
      });
      if (
        user.data.addresses?.length === 0 &&
        confirmSelectedAddress &&
        isCheckout &&
        closeManager
      ) {
        closeManager();
      }
    }
    setFormIsOpen(false);
  };

  const deleteAddress = (addressId: string) => {
    dispatch({
      type: UserActions.DELETE_ADDRESS,
      addressId,
    });
  };

  return (
    <>
      {/* Addresses */}
      {!formIsOpen && (
        <>
          <Title>Shipping Address</Title>
          {(user.data?.addresses ? [...user.data.addresses] : [])
            .sort((a, b) => (b.default ? 1 : -1))
            .map((address) => (
              <AddressContainer
                key={address.id}
                $selected={selectedAddress === address.id && isCheckout}
                $isCheckout={isCheckout}
                onClick={() => setSelectedAddress(address?.id)}
              >
                {address.default && (
                  <DefaultTag>
                    <Title as="h3">DEFAULT</Title>
                  </DefaultTag>
                )}
                <AddressBody>
                  <div>
                    <Title as="h3">{address.street}</Title>
                    <Text>{address.secondary}</Text>
                    <Text>{`${address.city}, ${address.state}`}</Text>
                    <Text>{address.postalCode}</Text>
                  </div>
                  <EditDeleteButtonsContainer>
                    <Button
                      size="sm"
                      iconName="Pencil"
                      onClick={() => {
                        setAddressToEdit(address);
                        setFormIsOpen(true);
                      }}
                      isIconOnly
                      variant="outline"
                    />
                    <Button
                      size="sm"
                      iconName="Trash"
                      onClick={() => deleteAddress(address.id ?? "")}
                      isIconOnly
                      variant="outline"
                      isLoading={user.isPending}
                    />
                  </EditDeleteButtonsContainer>
                </AddressBody>
              </AddressContainer>
            ))}
          <AddressContainer
            onClick={() => {
              setAddressToEdit(addressInitializerObject);
              setFormIsOpen(true);
            }}
            $buttonDecor
          >
            <AddressBody>
              <div className="add-new-address">
                <Button size="md" iconName="Plus" isIconOnly variant="outline" />
                <Text>Add a new shipping address</Text>
              </div>
            </AddressBody>
          </AddressContainer>
          {isCheckout && (
            <ConfirmButtonWrapper>
              <ConfirmButton
                size="lg"
                disabled={selectedAddress === null}
                onClick={() =>
                  confirmSelectedAddress ? confirmSelectedAddress(selectedAddress as string) : null
                }
                isLoading={newCase?.isPending}
                isBlock
                $pendingConfirmation={pendingAddressConfirmation}
              >
                Confirm
              </ConfirmButton>
            </ConfirmButtonWrapper>
          )}
        </>
      )}
      {/* Address Form */}
      {formIsOpen && (
        <>
          <AddressForm
            closeForm={() => setFormIsOpen(false)}
            address={addressToEdit}
            onSubmit={saveAddress}
            enableCardDecor={enableCardDecor}
          />
        </>
      )}
    </>
  );
};

export default AddressManager;
