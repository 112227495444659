import "./CheckoutPage.scss";

import Cookies from "js-cookie";
import moment from "moment-business-days-sk";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressManager from "@components/AddressManager";
import InfoSlider from "@components/elements/InfoSlider";
import PaymentMethodManager, { PaymentMethodManagerView } from "@components/PaymentMethodManager";
import useCreatePaymentMethod from "@hooks/useCreatePaymentMethod";
import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { useTitle } from "@hooks/useTitle";
import useValidatePaymentForm from "@hooks/useValidatePaymentMethod";
import {
  AlertBanner,
  AlertBannerMessage,
  Button,
  Card,
  Feedback,
  FormGroupMessage,
  Link,
  Option,
  Text,
  TextField,
  Title,
} from "@runwayhealth/runway-components-react";
import { PaymentFormField, PaymentMethodForm } from "@store/../@types/billing";
import { Case, ShippingMethods } from "@store/../@types/case";
import { ConditionTypes } from "@store/../@types/condition";
import { ErrorEvents, PricesState, RootEntity, RootState } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import * as casesActions from "@store/cases/casesActions";
import * as casesSlice from "@store/cases/casesSlice";
import {
  ADD_PAYMENT_METHOD,
  GET_AFFILIATE_PROFILE,
  GET_PROMO_CODES,
  LOGOUT,
} from "@store/user/userActions";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toastError, toastInfo } from "@utils/utils";

import { coupons } from "../../../api";
import Loader from "../../../img/Loader.svg";
// Visit Price ID (Stripe reference for visit fee.)
import { VISIT_FEE_ID } from "../../../utils/constantsEnv";
import Header from "../../elements/Header";
import { ShippingBody, ShippingContainer } from "./ShippingMethod";

const CheckoutPage = () => {
  const dispatch = useDispatch();

  // Context entities.
  const user = useSelector<RootState, RootEntity<User>>((state) => state.user);
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const userCases = useSelector<RootState, RootEntity<Case>[]>((state) => state.case.cases.data);
  // Services prices.
  const prices = useSelector<RootState, PricesState>((state) => state.price);
  const paymentMethodForm = useSelector<RootState, PaymentMethodForm>(
    (state) => state.paymentMethodForm
  );
  //Promo codes & discounts.
  const [promo, setPromo] = useState({
    discount: 0,
    havePromo: false,
    code: "",
    id: "",
  });
  // Checkout summary.
  const [totalMedicationPrice] = useState<number>(
    newCase.data.conditions.reduce((sum, condition) => sum + (condition?.price || 0), 0)
  );

  const [includesTyphoid] = useState<boolean>(
    newCase.data.conditions
      .map((condition) => condition.product.name)
      .includes(ConditionTypes.TYPHOID_FEVER)
  );

  const [shippingPriceAmount, setShippingPriceAmount] = useState(0);

  const [isReferred, setIsReferred] = useState(false);

  const [infoSliderIsOpen, setInfoSliderIsOpen] = useState(false);
  const [pendingAddressConfirmation, setPendingAddressConfirmation] = useState(true);
  const [promoCodeInputIsOpen, setPromoCodeInputIsOpen] = useState(false);

  const [checkoutIsReady, setCheckoutIsReady] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const [addressManagerIsOpen, setAddressManagerIsOpen] = useState(true);

  const { reportPurchasedProducts } = useDataLayer(GA4Events.BEGIN_CHECKOUT);

  const stripe = useStripe();
  const elements = useElements();

  const addDaysAndFormat = (date: Date, days: number) => {
    const dateWithDays = moment(date).businessAdd(days).toDate();
    return dateWithDays.toLocaleString("en-us", {
      month: "short",
      day: "numeric",
    });
  };

  // Payment Method Hook
  const { createPaymentMethod, serializePaymentMethodResult } = useCreatePaymentMethod();

  // Validation form hook.
  const { validateForm } = useValidatePaymentForm();

  // Delivery dates
  const today = new Date();
  const superfast = addDaysAndFormat(today, 2);
  const fastest = addDaysAndFormat(today, 3);
  const slowest = addDaysAndFormat(today, 8);
  const neutral = addDaysAndFormat(today, 5);

  const [errorText, setErrorText] = useState("");

  useTitle("Checkout");

  // Hook effects.

  // Find offers on component mount.
  useEffect(() => {
    // Get referral link cookie.
    const referralCookie = JSON.parse(Cookies.get("rewardful.referral") ?? "{}");

    // Find offers
    if (referralCookie.coupon) {
      // Fetchs affiliate profile.
      if (!user.data.affiliateProfile) {
        dispatch({
          type: GET_AFFILIATE_PROFILE,
        });
      }
    } else {
      // Find rewards.
      dispatch({
        type: GET_PROMO_CODES,
      });
    }
    // Collect analytics
    reportPurchasedProducts();
  }, []);

  // Checks errors
  useEffect(() => {
    const errorMessage = newCase?.error?.message || "";
    const showErrorMessage =
      !errorMessage.includes("Unknown") &&
      !errorMessage.toLocaleLowerCase().includes("sequelize") &&
      errorMessage.length > 0;
    if (newCase.error.event === ErrorEvents.ACCESS_DENIED) {
      sessionExpired();
    } else if (showErrorMessage) {
      if (errorMessage !== ErrorEvents.PENDING_INVOICE) {
        toastError("Error.", errorMessage);
      }
    }
  }, [newCase.error]);

  const sessionExpired = () => {
    toastInfo("Session expired", "Please login again to continue...");
    dispatch({
      type: LOGOUT,
      userId: user.data.id,
    });
  };

  // Set shipping prices.
  useEffect(() => {
    if (user.data.pharmacy_id) {
      setShipping(null);
    }

    // if (includesTyphoid) {
    //   setShipping(ShippingMethods.UPS_NEXT_DAY_AIR);
    // }

    // Set shipping price amount.
    switch (newCase.data.shipping_method) {
      case ShippingMethods.USPS_PRIORITY:
        setShippingPriceAmount(prices.shipping.data[0]?.fixed_amount?.amount ?? 0);
        break;
      case ShippingMethods.UPS_NEXT_DAY_AIR:
        setShippingPriceAmount(prices.shipping.data[1]?.fixed_amount?.amount ?? 0);
        break;
    }
  }, [prices, newCase.data.shipping_method]);

  // Validates referrals.
  useEffect(() => {
    if (user.data.affiliateProfile) {
      // Get referral link cookie.
      const referralCookie = JSON.parse(Cookies.get("rewardful.referral") ?? "{}");

      // If there is a referral cookie.
      if (referralCookie.coupon) {
        // Validate referral.
        let referralIsValid = false;
        // If referral token is equals to the cookie token, then is invalid.
        if (referralCookie.affiliate?.token !== user.data.affiliateProfile.links[0].token) {
          // Valid referral has no previous purchases.
          const userPaidCases = userCases.filter((case_: RootEntity<Case>) => case_.data.caseId);
          if (userPaidCases.length === 0) {
            referralIsValid = true;
          }
        }

        if (!referralIsValid) {
          // Remove the cookie.
          Cookies.remove("rewardful.referral");
          return;
        }
        coupons.couponGet(`/?id=${referralCookie.coupon.id}`).then((coupon) => {
          const discountAmount = prices.case.data.unit_amount * (coupon.data.percent_off / 100);
          setPromo((prev) => ({
            ...prev,
            discount: discountAmount,
            havePromo: true,
          }));
          setIsReferred(true);
        });
      }
    }
  }, [user.data.affiliateProfile]);

  useEffect(() => {
    const promocodes = user.data?.promocodes;
    if (promocodes && promocodes.length > 0) {
      // Find active promocodes for user.
      const activePromocodes = user.data.promocodes
        .filter((promocode) => promocode.active === true)
        .map((promocodeObject) => promocodeObject.code);

      if (activePromocodes.length > 0) {
        const code = activePromocodes[0];
        // Redundanly apply promo code to avoid
        // async state transition
        setPromo((prev) => ({ ...prev, code, havePromo: true }));
        applyPromoCode(code);
      }
    }
  }, [user.data.promocodes]);

  // Watches checkout forms.
  useEffect(() => {
    const paymentFormErrorState = Object.keys(paymentMethodForm)
      .filter((field) => field)
      .map((key) => paymentMethodForm[key as PaymentFormField].error?.flagged);

    const paymentFormIsComplete = Object.keys(paymentMethodForm)
      .filter((field) => field !== undefined)
      .map((key) => paymentMethodForm[key as PaymentFormField].value !== "");

    setCheckoutIsReady(
      (!paymentFormErrorState.includes(true) && !paymentFormIsComplete.includes(false)) ||
        user.data.paymentMethods?.length > 0
    );
  }, [newCase.data.shippingAddress, paymentMethodForm, user.data.paymentMethods]);

  const setShipping = (shipping: ShippingMethods | null) => {
    dispatch(casesSlice.updateShippingMethod(shipping));
    if (newCase.data.id) {
      dispatch({
        type: casesActions.UPDATE_CASE,
        caseId: newCase.data.id,
        updateBody: {
          shipping_method: shipping,
        },
      });
    }
  };

  const applyPromoCode = async (value?: string) => {
    // Retrieve for the promoCode.
    const promocode = value ?? promo.code;
    if (!promocode.length) setErrorText("Please enter your discount code");
    if (promocode !== "") {
      // Get promo code
      const codesResult = await coupons.couponGet(`/promo_code?code=${promocode}&active=true`);
      const promoCode = codesResult.data[0];

      // If the promo code is for a customer, but user does not have promocodes
      // it means it doesn't belong to this user, so is **exclusive**
      const promoCodeIsExclusive = promoCode?.customer && user.data.promocodes?.length === 0;
      // Validate the promo code
      const promoCodeIsValid =
        promoCode &&
        promoCode.coupon.metadata.price === VISIT_FEE_ID &&
        promoCode.coupon.valid &&
        !promoCodeIsExclusive;

      if (!promoCodeIsValid) {
        setErrorText("The code is invalid. Please enter a valid code.");
        return;
      }

      const discountAmount =
        promoCode.coupon.amount_off ??
        prices.case.data.unit_amount * (promoCode.coupon.percent_off / 100);

      setPromo((prev) => ({
        ...prev,
        id: promoCode.id,
        discount: discountAmount,
        havePromo: true,
      }));
      setErrorText("");
      // Save promocode in local storage for analytics purposes.
      localStorage.setItem("promoCode", promocode);
    }
  };

  const removePromoCode = async () => {
    setPromo((prev) => ({ ...prev, id: "", code: "", discount: 0, havePromo: false }));
  };

  // Should be handled properly with TypeScript.
  const handleCode = (e: any) => {
    const { value } = e.target;
    setPromo((prev) => ({ ...prev, code: value }));
  };

  const processCase = async () => {
    if (!newCase.data.shippingAddress?.id) {
      // Highlight address confirmation button.
      // User hint
      setPendingAddressConfirmation(!pendingAddressConfirmation);
      return;
    }

    setDisabledButton(true);
    if (user.data.paymentMethods.length === 0) {
      const validForm = validateForm(paymentMethodForm);

      if (!validForm) {
        setDisabledButton(false);
        return;
      }
      // Add the payment method first.
      const paymentMethodResult = await createPaymentMethod(
        paymentMethodForm,
        stripe,
        elements,
        CardNumberElement
      );

      if (paymentMethodResult?.paymentMethod) {
        const newPaymentMethod = serializePaymentMethodResult(paymentMethodResult.paymentMethod);
        dispatch({
          type: ADD_PAYMENT_METHOD,
          paymentMethodId: newPaymentMethod?.id,
          view: PaymentMethodManagerView.CHECKOUT,
        });
      }
    }
    dispatch({
      type: casesActions.PAY_CASE,
      newCase: newCase.data,
      paymentMethods: user.data.paymentMethods,
      promoCodeId: promo.id,
      transaction: user.data?.partnerTx,
    });

    const consultations = JSON.parse(sessionStorage.getItem("consultations") ?? "[]");
    dispatch({
      type: casesActions.SEND_CASE,
      newCase: newCase.data,
      isPlus: consultations.includes(ConditionTypes.RUNWAY_PLUS) ?? consultations.length === 0,
    });
    setDisabledButton(false);
  };

  const formattedPrice = (value: number, decimals = 0) => (value / 100).toFixed(decimals);

  const typhoidInfo = (
    <>
      <h2>Why we care about a faster shipping method?</h2>
      <hr />
      <p>
        The Typhoid Vaccine (Oral) requires refrigeration during storage and shipment. Express
        shipping, in specialized packaging, ensures the vaccine remains stable during transit.
      </p>
    </>
  );

  const setCaseShippingAddress = (addressId: string) => {
    dispatch({
      type: casesActions.UPDATE_CASE,
      caseId: newCase.data.id,
      updateBody: {
        shippingAddress: {
          id: addressId,
        },
      },
    });
    setAddressManagerIsOpen(false);
    setPendingAddressConfirmation(!pendingAddressConfirmation);
  };

  return (
    <>
      <Header hasProgressBar={false} />
      {/*  Flex container */}
      <div className="checkout-container">
        {/* Left flex column */}
        <div className="left-checkout-column">
          {/* Shipping container */}
          <div>
            <Card className="checkout-card">
              <>
                {addressManagerIsOpen ? (
                  <AddressManager
                    user={user}
                    openForm={!user.data.addresses || user.data.addresses.length === 0}
                    confirmSelectedAddress={setCaseShippingAddress}
                    newCase={newCase}
                    closeManager={() => setAddressManagerIsOpen(false)}
                    pendingAddressConfirmation={pendingAddressConfirmation}
                    isCheckout
                  />
                ) : (
                  <>
                    <div className="ph-no-capture shipping-address-container">
                      <div>
                        <Title as="h2" size="md-bold">
                          Shipping Address
                        </Title>
                        <Button
                          size="sm"
                          variant="outline"
                          iconName="Pencil"
                          iconPosition="left"
                          onClick={() => setAddressManagerIsOpen(true)}
                        >
                          Edit
                        </Button>
                      </div>
                      {newCase.isPending ? (
                        <>
                          {" "}
                          <img className="loader-img" src={Loader} />
                        </>
                      ) : (
                        <div className="shipping-address">
                          <Text>
                            {user.data.first_name} {user.data.last_name},
                          </Text>
                          <Text>
                            {newCase.data.shippingAddress?.street},{" "}
                            {newCase.data.shippingAddress?.city},{" "}
                            {newCase.data.shippingAddress?.state}
                          </Text>
                          <Text>{newCase.data.shippingAddress?.postalCode}</Text>
                        </div>
                      )}
                    </div>
                    <div className="medicine-fee">
                      <Title as="h2" size="md-bold">
                        Shipping Method
                      </Title>
                      {newCase.data.conditions.length === 0 && (
                        <p>In case you get a prescription, please choose a shipping method:</p>
                      )}
                      <ShippingContainer>
                        <Option
                          id="standard"
                          checked={newCase.data.shipping_method === null}
                          value="standard"
                          onClick={() => {
                            setShipping(null);
                            setShippingPriceAmount(0);
                          }}
                          //isDisabled={includesTyphoid}
                        >
                          <ShippingBody>
                            <div className="shipping-body-info">
                              <div>
                                <Text as="span" size="sm" noMarginBottom>
                                  Estimated arrival between
                                </Text>
                                <Title as="h3" size="sm" noMarginBottom>
                                  {`${neutral} - ${slowest}`}
                                </Title>
                              </div>
                              <div>
                                <Title as="h3" size="tiny" noMarginBottom>
                                  Standard
                                </Title>
                                <Text as="span" size="sm" noMarginBottom>
                                  5-8 business days
                                </Text>
                              </div>
                            </div>
                            <div className="shipping-body-price">
                              <Title as="h3" size="md-bold" className="free-price" noMarginBottom>
                                FREE
                              </Title>
                            </div>
                          </ShippingBody>
                        </Option>

                        <Option
                          id="priority"
                          checked={newCase.data.shipping_method === ShippingMethods.USPS_PRIORITY}
                          value="priority"
                          onClick={() => {
                            setShipping(ShippingMethods.USPS_PRIORITY);
                            setShippingPriceAmount(
                              prices.shipping.data[0]?.fixed_amount?.amount ?? 0
                            );
                          }}
                          //isDisabled={includesTyphoid}
                        >
                          <ShippingBody>
                            <div className="shipping-body-info">
                              <div>
                                <Text as="span" size="sm" noMarginBottom>
                                  Estimated arrival between
                                </Text>
                                <Title as="h3" size="sm" noMarginBottom>
                                  {`${fastest} - ${neutral}`}
                                </Title>
                              </div>
                              <div>
                                <Title as="h3" size="tiny" noMarginBottom>
                                  Priority
                                </Title>
                                <Text as="span" size="sm" noMarginBottom>
                                  3-5 business days
                                </Text>
                              </div>
                            </div>
                            <div className="shipping-body-price">
                              <Title as="h3" size="md-bold" noMarginBottom>
                                $
                                {prices.shipping.data.length > 0
                                  ? formattedPrice(
                                      prices.shipping.data[0]?.fixed_amount?.amount || 0,
                                      2
                                    )
                                  : 0}
                              </Title>
                            </div>
                          </ShippingBody>
                        </Option>
                        <Option
                          id="express"
                          checked={
                            newCase.data.shipping_method === ShippingMethods.UPS_NEXT_DAY_AIR
                          }
                          value="express"
                          onClick={() => {
                            setShipping(ShippingMethods.UPS_NEXT_DAY_AIR);
                            setShippingPriceAmount(
                              prices.shipping.data[1]?.fixed_amount?.amount ?? 0
                            );
                          }}
                        >
                          <ShippingBody>
                            <div className="shipping-body-info">
                              <div>
                                <Text as="span" size="sm" noMarginBottom>
                                  Estimated arrival between
                                </Text>
                                <Title as="h3" size="sm" noMarginBottom>
                                  {`${superfast} - ${fastest}`}
                                </Title>
                              </div>
                              <div>
                                <Title as="h3" size="tiny" noMarginBottom>
                                  Express
                                </Title>
                                <Text as="span" size="sm" noMarginBottom>
                                  2-3 business days
                                </Text>
                              </div>
                            </div>
                            <div className="shipping-body-price">
                              <Title as="h3" size="md-bold" noMarginBottom>
                                $
                                {prices.shipping.data.length > 0
                                  ? formattedPrice(
                                      prices.shipping.data[1]?.fixed_amount?.amount || 0,
                                      2
                                    )
                                  : 0}
                              </Title>
                            </div>
                          </ShippingBody>
                        </Option>
                      </ShippingContainer>
                    </div>
                  </>
                )}
              </>
              {/* {includesTyphoid && (
                <AlertBanner variant="warning" isRounded>
                  <AlertBannerMessage>
                    The Typhoid Vaccine (Oral) requires Express Shipping ($45). Your shipping will
                    automatically be set to accommodate this requirement.
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setInfoSliderIsOpen(true);
                      }}
                    >
                      Learn more.
                    </Link>
                  </AlertBannerMessage>
                </AlertBanner>
              )} */}
            </Card>
          </div>
          {/* Payment details container */}
          <div>
            <div>
              <>
                <PaymentMethodManager
                  stripeHook={stripe}
                  stripeElementsHook={elements}
                  user={user}
                  view={PaymentMethodManagerView.CHECKOUT}
                />
              </>
            </div>
          </div>
        </div>
        {/* Right flex column */}
        <div className="right-checkout-column">
          {/* Order summary container */}
          <div>
            <Card className="checkout-card">
              <div>
                <Title as="h2" size="md-bold">
                  Due now
                </Title>
                {/* Consultation fee */}
                {!user.isPending ? (
                  <>
                    <div className="highlighted-fee">
                      <Title as="h3" size="sm">
                        Consultation Fee
                      </Title>
                      <Title as="span" size="sm">
                        {user.data?.partnerTx
                          ? "$0"
                          : `$${formattedPrice(prices.case.data.unit_amount - promo.discount)}`}
                      </Title>
                    </div>
                    {promo.havePromo && (
                      <Title className="crossed-out-visit-fee" as="h3" size="sm">
                        $30
                      </Title>
                    )}
                    {isReferred && (
                      <div className="banner-wrapper">
                        {" "}
                        <AlertBanner variant="success" isRounded>
                          <AlertBannerMessage>Referral discount applied.</AlertBannerMessage>
                        </AlertBanner>
                      </div>
                    )}
                  </>
                ) : (
                  // Consultation Fee Loader
                  <>
                    {" "}
                    <img className="loader-img" src={Loader} />
                  </>
                )}
                {/* Treatment summary */}
                <hr></hr>
                <div>
                  {newCase.data.conditions.length > 0 && (
                    <>
                      {" "}
                      <Title as="h2" size="md-bold">
                        Due only if prescribed
                      </Title>
                      <div className="highlighted-fee">
                        <Title as="h3" size="sm">
                          Treatment Total
                        </Title>
                        <Title as="h3" size="sm">
                          ${formattedPrice(totalMedicationPrice)}
                        </Title>
                      </div>
                      {newCase.data.conditions.map((condition, index) => {
                        return (
                          <div className="treatment-fee" key={index}>
                            <Text size="md">{condition.product.name}</Text>
                            <Text size="md">${formattedPrice(condition?.price || 0)}</Text>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <div className="shipping-fee">
                    <Title as="h3" size="sm">
                      Shipping Fee
                    </Title>
                    <Title as="h3" size="sm">
                      ${formattedPrice(shippingPriceAmount)}
                    </Title>
                  </div>
                </div>
                {/* Order Total */}
                <hr></hr>
                <div className="order-total-wrapper">
                  <Title as="h2" size="md-bold">
                    Order total
                  </Title>
                  <Title as="h2" size="md-bold">
                    $
                    {formattedPrice(
                      totalMedicationPrice +
                        shippingPriceAmount +
                        prices.case.data.unit_amount -
                        promo.discount
                    )}
                  </Title>
                </div>
              </div>
              {/* Promocode Link */}
              <div className="promocode-link-wrapper">
                {!isReferred &&
                  !promo.havePromo &&
                  !user.data?.partnerTx &&
                  !promoCodeInputIsOpen && (
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPromoCodeInputIsOpen(true);
                      }}
                    >
                      Have a discount code?
                    </Link>
                  )}
              </div>
              {/* Promocode Section Title */}
              {((promo.havePromo && promo.id) || promoCodeInputIsOpen) && (
                <Title as="h2" size="md-bold">
                  Have a discount code?
                </Title>
              )}
              {/* Promocode Input */}
              <div>
                {promoCodeInputIsOpen && !promo.havePromo && (
                  <>
                    {" "}
                    <div className="promocode-input-wrapper">
                      <TextField
                        placeholder="Discount code"
                        onChange={handleCode}
                        isInvalid={errorText !== ""}
                      />
                      <FormGroupMessage>
                        <Feedback size="sm" isInvalid={errorText !== ""}>
                          {errorText}
                        </Feedback>
                      </FormGroupMessage>
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          applyPromoCode();
                        }}
                      >
                        Apply code
                      </Link>
                    </div>
                  </>
                )}
              </div>
              {/* Applied promocode */}
              {promo.havePromo && promo.id && (
                <div>
                  <div className="promocode-input-wrapper">
                    <TextField
                      placeholder="Discount code"
                      onChange={handleCode}
                      value={promo.code}
                      disabled
                    />
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault;
                        removePromoCode();
                      }}
                    >
                      Remove code
                    </Link>
                  </div>
                  <div className="banner-wrapper">
                    {" "}
                    <AlertBanner variant="success" isRounded>
                      <AlertBannerMessage>
                        Your discount code has been applied successfully.
                      </AlertBannerMessage>
                    </AlertBanner>
                  </div>
                </div>
              )}
              <div className="pay-button-container">
                <Button
                  size="lg"
                  isLoading={newCase.isPending}
                  disabled={
                    user.isPending || newCase.isPending || !checkoutIsReady || disabledButton
                  }
                  onClick={processCase}
                  customWidth={400}
                >
                  {/* {`Pay ${
                    user.data?.partnerTx
                      ? "$0"
                      : `$${formattedPrice(prices.case.data.unit_amount - promo.discount)}`
                  } today`} */}
                  Complete Payment
                </Button>
                <div className="payment-agreement-notice">
                  <p>By clicking "Complete Payment" you agree that:</p>
                  <p>
                    If prescribed, your credit card will be charged for the amount indicated in your
                    treatment total and shipping fee.
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      {/* <InfoSlider
        open={infoSliderIsOpen}
        close={() => setInfoSliderIsOpen(false)}
        child={typhoidInfo}
      /> */}
    </>
  );
};

export default CheckoutPage;
