import * as Sentry from "@sentry/react";
import { SMARTY_EMBEDDED_KEY } from "@utils/constantsEnv";

export type AddressSuggestion = {
  street_line: string;
  secondary?: string;
  city: string;
  state: string;
  zipcode: string;
  full_address: string;
  entries?: number;
};

const useAddressSuggestion = () => {
  const url = `https://us-autocomplete-pro.api.smarty.com/lookup?key=${SMARTY_EMBEDDED_KEY}&search=`;
  const getSuggestionResults = async (query: string): Promise<AddressSuggestion[]> => {
    let suggestions: AddressSuggestion[] = [];
    try {
      const response = await fetch(`${url}${query}`);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const { suggestions: results } = await response.json();

      suggestions = results
        .filter((result: AddressSuggestion) => !result.street_line.includes("PO Box"))
        .map((address: AddressSuggestion) => {
          const { entries, secondary, ...cleanObject } = address;
          return {
            ...cleanObject,
            full_address: Object.values(cleanObject).join(" "),
          };
        });
    } catch (err) {
      Sentry.captureException(err);
    }

    return suggestions;
  };

  return { getSuggestionResults };
};

export default useAddressSuggestion;
