import React, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import ReactPlayer from "react-player";
import axios from "axios";
import dayjs from "dayjs";
import { UserType } from "../../../../../../../@types/mdi";

import "./message.scss";

const Message = ({ message, userType, time, file, memberFirstName }) => {
  const divRef = useRef(null);
  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const urlFormat = (url) => {
    if (!url) {
      return;
    }
    const format = url.split(".");
    return format[format.length - 1];
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const FilePreview = () => {
    if (file.mime_type.includes("image")) {
      return (
        <a href={file.url} target="blank">
          {file.name}
          <img style={{ width: "100%" }} src={file.url} alt={file.name} />
        </a>
      );
    } else if (file.mime_type.includes("video")) {
      return <ReactPlayer controls url={file.url} width="100%" height="100%" />;
    } else {
      return (
        <a href={file.url} target="blank">
          📑{file.name}
        </a>
      );
    }
  };

  return (
    <div
      ref={divRef}
      className={`message ${userType === UserType.PATIENT ? "my-message" : ""} ph-no-capture`}
    >
      <div className="message-wrapper">
        {userType === UserType.CLINICIAN && (
          <div className="row">
            <div className="col-md-12">
              <p style={{ color: "#335a5a" }}>💊 Dr. {memberFirstName}</p>
            </div>
          </div>
        )}
        {userType === UserType.SUPPORT && (
          <div className="row">
            <div className="col-md-12">
              <p style={{ color: "#82890f" }}>👩🏻‍💻 Runway Support</p>
            </div>
          </div>
        )}
        {userType === UserType.PATIENT && (
          <div className="row">
            <div className="col-md-12">
              <p style={{ textAlign: "right " }}>You</p>
            </div>
          </div>
        )}
        <div
          className="message-text"
          style={{ background: `${userType === UserType.SUPPORT ? "#fffef1" : ""}` }}
        >
          {file ? <FilePreview /> : null}
          <span>{message}</span>
        </div>
        <div className={"message-time"}>
          <span className="time">{dayjs(time).format("MMMM D, YYYY hh:mm A")}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Message);
