import { LANDING_PAGE } from "@utils/constantsEnv";

type WooMetadata = {
  id: number;
  key: string;
  value: string;
};

type WooProduct = {
  id: string;
  meta_data: WooMetadata[];
  condition_sidebar_description: string;
};

const useSidebarContent = () => {
  const getConditionDescription = async (productId: string): Promise<string> => {
    try {
      const url = `${LANDING_PAGE}wp-json/wc/v3/products?per_page=20`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_WOO_CONSUMER_KEY}:${process.env.REACT_APP_WOO_CONSUMER_SECRET}`
          )}`,
        },
      });

      if (!response.ok) {
        return "Information not found.";
      }

      const products = await response.json();

      const product = products.find((product: WooProduct) => {
        const metadataMatch = product.meta_data.find((metadata: WooMetadata) => {
          return metadata.value === productId;
        });
        return metadataMatch !== undefined;
      });

      if (!product) {
        return "Information not found.";
      }

      return (
        product.meta_data.filter(
          (metadata: WooMetadata) => metadata.key === "condition_sidebar_description"
        )[0].value || "Description not available."
      );
    } catch (error) {
      return "Information not found.";
    }
  };

  return { getConditionDescription };
};

export default useSidebarContent;
