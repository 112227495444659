import styled from "styled-components";

import { Button, from, Option, Text } from "@runwayhealth/runway-components-react";

const ConditionContent = `  
  display: flex;
  align-items: center;
  height: 100%;
  background: white;
  gap: 8px;
  min-width: 250px;
  width: 100%;
`;

export const ConditionOption = styled(Option)`
  ${ConditionContent}
  label {
    padding: 5px 15px 4px 20px !important;
  }
  @media ${from.mobileS} {
    margin-bottom: 5px;
  }
  div[variant="radio"] {
    margin-top: 18px;
    margin-bottom: 19px;
  }
`;

export const ConditionCard = styled.div`
  ${ConditionContent}
  border: 1px solid #95b6fb;
  border-radius: 13px;
  border-width: 2px;
  padding: 8px 12px 4px 12px;
  @media ${from.desktop} {
    margin-bottom: 5px;
    width: 45%;
  }
`;

export const CardInfoWrapper = styled.div<{ $emptyDescription?: boolean; $upsellView?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${(props) => (props.$upsellView ? "margin-left: 15px;" : null)};
`;

export const DescriptionWrapper = styled.div<{ $emptyDescription?: boolean }>`
  ${(props) =>
    props.$emptyDescription &&
    `  
    display: flex;
    align-items: center;
  `}
  text-align: left;
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 179px;
  align-items: center;
  .info-button-wrapper {
    padding-left: 5px;
  }
`;

export const ConditionText = styled(Text)<{ $reducedWeight?: boolean }>`
  ${(props) => (props.$reducedWeight ? "font-weight: 200;" : "font-weight: 700;")};
  ${(props) => (props.$reducedWeight ? "font-size: 13px;" : "font-size: 1rem;")};
  margin-bottom: 1px;
`;

export const ConditionActionButton = styled(Button)`
  border-width: 0px;
  box-shadow: none;
  width: 20px;
  height: 20px;
`;

export const InfoButton = styled(Button)`
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-width: 0px;
  box-shadow: none;
  color: #96b6fa;
  width: 20px;
  height: 20px;
`;
