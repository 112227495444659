import styled from "styled-components";

import { border, from, spacing, Title } from "@runwayhealth/runway-components-react";

export const OrderSummary = styled.div<{ $startPage?: boolean }>`
  margin-top: ${(props) => (props.$startPage ? spacing.xxs : "0px")};
  border-top: ${(props) => (props.$startPage ? `${border.width.hairline} solid #e0e0e0;` : "0px")};
  margin-bottom: 6px;

  .order-items-title {
    margin: -8px auto 0 auto;
    background: #f7f8f9;
    width: fit-content;
    padding: 0 8px;
  }

  .upsell-title {
    text-align: left;
  }

  .order-items-container {
    padding: 8px 0 2px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    @media ${from.mobile} {
      padding: 22px 0 16px 22px;
    }

    &.single-order {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const SummaryTitle = styled(Title)`
  text-align: center;
`;
