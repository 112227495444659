import { ConditionTypes } from "@store/../@types/condition";

import MalariaProphylaxis from "../../src/img/orders/Color=darkblue, Size=1x, Patch=False.png";
import NauseaPrevention from "../../src/img/orders/Color=gray, Size=0.2x, Patch=False.png";
import UrinaryTractInfection from "../../src/img/orders/Color=lavender, Size=0.1x, Patch=False.png";
import RunwayPlus from "../../src/img/orders/Color=lightblue, Size=0.2x, Patch=False.png";
import MotionSickness from "../../src/img/orders/Color=lightblue, Size=0.2x, Patch=True.png";
import AltitudeSickness from "../../src/img/orders/Color=lightgreen, Size=0.2x, Patch=False.png";
import VaginalYeastInfection from "../../src/img/orders/Color=pink, Size=0.1x, Patch=False.png";
import TravelDiarrhea from "../../src/img/orders/Color=teal, Size=0.1x, Patch=False.png";
import TyphoidFever from "../../src/img/orders/Color=vivotif, Size=1x, Patch=False.png";
import Insomnia from "../../src/img/orders/Color=yellow, Size=0.1x, Patch=False-1.png";

const productImages = {
  [ConditionTypes.ALTITUDE_CONSULTATION]: AltitudeSickness,
  [ConditionTypes.MALARIA_CONSULTATION]: MalariaProphylaxis,
  [ConditionTypes.MOTION_CONSULTATION]: MotionSickness,
  [ConditionTypes.NAUSEA_CONSULTATION]: NauseaPrevention,
  [ConditionTypes.RUNWAY_PLUS]: RunwayPlus,
  [ConditionTypes.INSOMNIA_CONSULTATION]: Insomnia,
  [ConditionTypes.DIARRHEA_CONSULTATION]: TravelDiarrhea,
  [ConditionTypes.TYPHOID_FEVER]: TyphoidFever,
  [ConditionTypes.URINARY_TRACT_INFECTION]: UrinaryTractInfection,
  [ConditionTypes.VAGINAL_YEAST_INFECTION]: VaginalYeastInfection,
};

export const getProductImage = (condition: ConditionTypes) => {
  return productImages[condition] || productImages[ConditionTypes.RUNWAY_PLUS];
};
