import { Case, CaseUpdate } from "@store/../@types/case";
import { Condition } from "@store/../@types/condition";
import { PricesState, QuestionsStateData } from "@store/../@types/state";
import { Gender } from "@store/../@types/user";
import { PaymentMethod } from "@stripe/stripe-js";

export const SET_CONDITIONS = "SET_CONDITIONS";
export interface SetConditions {
  type: typeof SET_CONDITIONS;
  consultation?: string;
  pricesIds?: string[];
}

export const FINISH_CONSULTATION = "FINISH_CONSULTATION";
export interface FinishConsultation {
  type: typeof FINISH_CONSULTATION;
  newCase: Case;
  userId?: string;
}

export const CREATE_CASE = "CREATE_CASE";
export interface CreateCase {
  type: typeof CREATE_CASE;
  newCase: Case;
}

export const UPDATE_CASE = "UPDATE_CASE";
export interface UpdateCase {
  type: typeof UPDATE_CASE;
  caseId: number;
  updateBody: CaseUpdate;
}

export const PAY_CASE = "PAY_CASE";
export interface PayCase {
  type: typeof PAY_CASE;
  newCase: Case;
  paymentMethods: PaymentMethod[];
  promoCodeId?: string;
  transaction?: string;
}

export const SEND_CASE = "SEND_CASE";
export interface SendCase {
  type: typeof SEND_CASE;
  newCase: Case;
  isPlus: boolean;
}

export const REQUEST_CASES = "REQUEST_CASES";
export interface RequestCases {
  type: typeof REQUEST_CASES;
  userId: string;
}

export const REFRESH_CASES = "REFRESH_CASES";
export interface RefreshCases {
  type: typeof REFRESH_CASES;
  userId: string;
}

export const REQUEST_ORDER_INVOICE = "REQUEST_ORDER_INVOICE";
export interface RequestOrderInvoice {
  type: typeof REQUEST_ORDER_INVOICE;
  orderId: number; // Runway ID
  caseId: number;
}

export const PAY_ORDER = "PAY_ORDER";
export interface PayOrder {
  type: typeof PAY_ORDER;
  orderId: string; // Shared ID
  caseId: number;
}

export const SEND_ORDER = "SEND_ORDER";
export interface SendOrder {
  type: typeof SEND_ORDER;
  orderId: string; // Shared ID
  caseId: number;
}

export const MAP_CONDITION_PRESCRIPTIONS = "MAP_CONDITION_PRESCRIPTIONS";
export interface MapConditionPrescriptions {
  type: typeof MAP_CONDITION_PRESCRIPTIONS;
  conditions: Condition[];
  questions: QuestionsStateData;
  prices: PricesState;
  upsell?: boolean;
}

export const UPSELL_CASE = "UPSELL_CASE";
export interface UpsellCase {
  type: typeof UPSELL_CASE;
  destination: string;
  userGender: Gender;
  chosenConditions: Condition[];
}
