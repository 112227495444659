import { useState } from "react";

import {
  CardInfoWrapper,
  ConditionActionButton,
  ConditionCard,
  ConditionOption,
  ConditionText,
  DescriptionWrapper,
  InfoButton,
  PriceWrapper,
} from "@components/elements/Condition";
import InfoSlider from "@components/elements/InfoSlider";
import { Condition as ConditionType, ConditionTypes } from "@store/../@types/condition";
import { getProductImage } from "@utils/productImageManager";

import Description from "./components/description";

type Props = {
  condition: ConditionType;
  removeCondition?: (condition: string) => void;
  addCondition?: (condition: string) => void;
  dashboardView?: boolean;
  cartView?: boolean;
  upsellView?: boolean;
  addonConditions?: ConditionType[];
};

const Condition = ({
  condition,
  removeCondition,
  addCondition,
  dashboardView,
  cartView,
  upsellView,
  addonConditions = [],
}: Props) => {
  const [infoSliderIsOpen, setInfoSliderIsOpen] = useState(false);
  const [standardCartView, setStandardCartView] = useState(cartView && !upsellView);
  const [standardUpsellView, setStandardUpsellView] = useState(!cartView && upsellView);

  const CardContent = (
    <>
      <img
        width="40px"
        src={getProductImage(condition.product.name as ConditionTypes)}
        alt={condition.suggestedMedication?.name}
      />
      <CardInfoWrapper
        $upsellView={standardUpsellView}
        $emptyDescription={!condition.suggestedMedication?.name && dashboardView}
      >
        <DescriptionWrapper
          $emptyDescription={!condition.suggestedMedication?.name && dashboardView}
        >
          <ConditionText>{condition.product.name}</ConditionText>

          {standardCartView && (
            <PriceWrapper>
              <ConditionText>{`$${(condition.price ?? 0) / 100}`}</ConditionText>
              <div className="info-button-wrapper">
                <InfoButton
                  isIconOnly
                  iconName="Info"
                  variant="outline"
                  onClick={() => {
                    setInfoSliderIsOpen(true);
                  }}
                ></InfoButton>
              </div>
            </PriceWrapper>
          )}
          {condition.suggestedMedication?.name && (
            <PriceWrapper>
              <ConditionText $reducedWeight={true}>
                {condition.suggestedMedication?.name}
              </ConditionText>
            </PriceWrapper>
          )}
        </DescriptionWrapper>
        {standardCartView && (
          <ConditionActionButton
            isIconOnly
            iconName={removeCondition ? "Trash" : "Plus"}
            variant="outline"
            onClick={() => {
              if (removeCondition) {
                removeCondition(condition.product.name);
              } else if (addCondition) {
                addCondition(condition.product.name);
              }
            }}
          ></ConditionActionButton>
        )}
        {(condition.suggestedMedication?.name || dashboardView || upsellView) && (
          <InfoButton
            isIconOnly
            iconName="Info"
            variant="outline"
            onClick={() => {
              setInfoSliderIsOpen(true);
            }}
          ></InfoButton>
        )}
      </CardInfoWrapper>
    </>
  );
  return (
    <>
      {standardUpsellView ? (
        <ConditionOption
          checked={addonConditions
            .map((addedCondition) => addedCondition.product.id)
            .includes(condition.product.id)}
          onClick={() => {
            const isAdded = addonConditions
              .map((addonCondition) => addonCondition?.product.id)
              .includes(condition.product.id);

            if (isAdded) {
              removeCondition && removeCondition(condition.product.name);
            } else {
              addCondition && addCondition(condition.product.name);
            }
          }}
        >
          {CardContent}
        </ConditionOption>
      ) : (
        <ConditionCard>{CardContent}</ConditionCard>
      )}
      <InfoSlider
        open={infoSliderIsOpen}
        close={() => setInfoSliderIsOpen(false)}
        child={() => <Description productId={condition.product.id} />}
      />
    </>
  );
};

export default Condition;
