import { lazy, Suspense, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Condition from "@components/Condition";
import { OrderSummary } from "@components/layouts/StartConsultationPage";
import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { Title } from "@runwayhealth/runway-components-react";
import { Case } from "@store/../@types/case";
import {
  Condition as ConditionType,
  ConditionTypes,
  conditionTypesIndex,
  UrlConditionTypes,
} from "@store/../@types/condition";
import { PricesState, RootEntity, RootState } from "@store/../@types/state";
import { SET_CONDITIONS } from "@store/cases/casesActions";
import { removeConditions, setConditions } from "@store/cases/casesSlice";
import { REQUEST_CASE_PRICE, REQUEST_SHIPPING_PRICE } from "@store/prices/pricesActions";
import { OVERNIGHT_SHIPPING_ID, PRIORITY_SHIPPING_ID, VISIT_FEE_ID } from "@utils/constantsEnv";

// Lazy components
const ConditionsPicker = lazy(() => import("@components/Cart/components/ConditionsPicker"));

export enum PresentationType {
  DASHBOARD = "dashboard",
  START_CONSULTATION_PAGE = "start_consultation_page",
  UPSELL = "upsell",
}

interface CartProps {
  items: string[];
  country: string;
  includesTyphoid?: boolean;
  presentationType?: PresentationType;
  upsellConditions?: ConditionType[];
  addonConditions?: ConditionType[];
  setAddonConditions?: React.Dispatch<React.SetStateAction<ConditionType[]>>;
}

const Cart = ({
  items,
  country,
  includesTyphoid,
  presentationType = PresentationType.START_CONSULTATION_PAGE,
  upsellConditions,
  addonConditions,
  setAddonConditions,
}: CartProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const prices = useSelector<RootState, PricesState>((state) => state.price);
  const consultation = conditionTypesIndex.get(
    history.location.pathname.split("/")[1] as UrlConditionTypes
  );

  // Handle Analytic Events
  const { reportAddToCart } = useDataLayer(GA4Events.ADD_TO_CART);
  const { reportRemoveFromCart } = useDataLayer(GA4Events.REMOVE_FROM_CART);

  // Reference for previous conditions - to track additions or removals of the analyitcs events above
  const prevConditionsRef = useRef<ConditionType[]>(newCase.data.conditions);

  // Load cart elements.
  useEffect(() => {
    if (newCase.data.conditions.length === 0) {
      if (consultation !== ConditionTypes.RUNWAY_PLUS) {
        dispatch({
          type: SET_CONDITIONS,
          consultation,
        });
      }

      if (items.length === 0) {
        dispatch(setConditions({ conditions: [] }));
      } else {
        dispatch({
          type: SET_CONDITIONS,
          pricesIds: items,
        });
      }
    }
  }, []);

  useEffect(() => {
    // Set country state if any is selected.
    if (country) sessionStorage.setItem("country", country);
  }, []);

  // Get services prices.
  useEffect(() => {
    dispatch({
      type: REQUEST_CASE_PRICE,
      priceId: VISIT_FEE_ID,
    });
    dispatch({
      type: REQUEST_SHIPPING_PRICE,
      shippingRatesIds: [PRIORITY_SHIPPING_ID, OVERNIGHT_SHIPPING_ID],
    });
  }, []);

  // useEffect to track additions or removals for analytics events
  useEffect(() => {
    // Capture the previous and current conditions
    const prevConditions = prevConditionsRef.current;
    const currentConditions = newCase.data.conditions;

    // Add to Cart event
    if (currentConditions.length > prevConditions.length) {
      const addedConditions = currentConditions.filter(
        (condition) => !prevConditions.includes(condition)
      );

      addedConditions.forEach((condition) => console.log(condition.product.name));
      if (addedConditions.length > 0) {
        reportAddToCart(addedConditions[0]);
      }
    }

    // Remove from cart event
    if (currentConditions.length < prevConditions.length) {
      const removedConditions = prevConditions.filter(
        (condition) => !currentConditions.includes(condition)
      );

      removedConditions.forEach((condition) => console.log(condition.product.name));
      if (removedConditions.length > 0) {
        reportRemoveFromCart(removedConditions[0]);
      }
    }

    prevConditionsRef.current = currentConditions;
  }, [newCase.data.conditions]);

  const removeCondition = (condition: string) => {
    dispatch(removeConditions(condition));
  };

  return (
    <>
      <OrderSummary $startPage={presentationType === PresentationType.START_CONSULTATION_PAGE}>
        {presentationType === PresentationType.START_CONSULTATION_PAGE && (
          <Title size="xs" as="h3" className="order-items-title">
            Your Order
          </Title>
        )}
        {presentationType === PresentationType.UPSELL && (
          <Title className="upsell-title">Current Selection</Title>
        )}
        {!newCase.isPending && !prices.prescriptions.isPending ? (
          <div className="order-items-container">
            {newCase.data.conditions.map((condition, index) => (
              <Condition
                key={`condition-${index}`}
                removeCondition={!upsellConditions ? removeCondition : undefined}
                condition={condition}
                cartView={true}
                upsellView={presentationType === PresentationType.UPSELL}
                addonConditions={addonConditions}
              />
            ))}
            {/* Set Runway Plus as Default Condition */}
            {newCase.data.conditions.length === 0 && (
              <Condition
                key={"condition-0"}
                removeCondition={!upsellConditions ? removeCondition : undefined}
                condition={{
                  product: {
                    id: "",
                    name: ConditionTypes.RUNWAY_PLUS,
                    units: 1,
                    metadata: {
                      name: "Visit Fee",
                      medication: prices.case.data.id,
                    },
                  },
                  price: prices.case.data.unit_amount,
                  suggestedMedication: {
                    quantity: 1,
                  },
                }}
                cartView={true}
                upsellView={presentationType === PresentationType.UPSELL}
                addonConditions={addonConditions}
              />
            )}
          </div>
        ) : (
          <>...</>
        )}
      </OrderSummary>
      <Suspense fallback={<>...</>}>
        <ConditionsPicker
          presentationType={presentationType}
          upsellConditions={upsellConditions}
          addonConditions={addonConditions}
          setAddonConditions={setAddonConditions}
        />
      </Suspense>
    </>
  );
};

export default Cart;
