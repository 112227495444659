import "./assets/scss/PaymentMethodManager.scss";

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { Button, Card, Text, Title } from "@runwayhealth/runway-components-react";
import { CardLogoIndex, PaymentManagerSetup, PaymentMethod } from "@store/../@types/billing";
import { RootEntity } from "@store/../@types/state";
import { User } from "@store/../@types/user";
import {
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  SET_DEFAULT_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
} from "@store/user/userActions";
import { Stripe, StripeElements } from "@stripe/stripe-js";

import Loader from "../../img/Loader.svg";
import ConfirmationModal from "../elements/ConfirmationModal";
import {
  AddEditButtonsContainer,
  AddPaymentMethodButtonWrapper,
  PaymentBody,
  PaymentContainer,
  PaymentOption,
} from "../elements/PaymentMethod";
import PaymentForm from "./components/PaymentForm/PaymentForm";

export enum PaymentMethodManagerView {
  CHECKOUT = "checkout",
  PROFILE_SETTINGS = "profileSettings",
}

interface PaymentMethodManagerProps {
  stripeHook: Stripe | null;
  stripeElementsHook: StripeElements | null;
  user: RootEntity<User>;
  view: PaymentMethodManagerView;
  cardHidden?: boolean;
  promoCodeId?: string;
}

const PaymentMethodManager = ({
  stripeHook,
  stripeElementsHook,
  user,
  view,
  cardHidden,
}: PaymentMethodManagerProps) => {
  const dispatch = useDispatch();
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const paymentMethods = user.data.paymentMethods ?? [];
  const [managerSetup, setManagerSetup] = useState<PaymentManagerSetup>(PaymentManagerSetup.ADD);
  const [removalPaymentMethod, setRemovalPaymentMethod] = useState("");

  // We use this to keep track of previous payment methods state.
  // Our interests on this are to keep track in analytics.
  const prevPaymentMethodsRef = useRef<PaymentMethod[] | undefined>(undefined);
  const { reportPurchasedProducts } = useDataLayer(GA4Events.ADD_PAYMENT_INFO);
  const paymentMethodsChangesRef = useRef<number>(0);

  useEffect(() => {
    dispatch({ type: GET_PAYMENT_METHODS });
  }, []);

  useEffect(() => {
    // Count changes in payment methods.
    paymentMethodsChangesRef.current += 1;
    if (paymentMethods.length > 0) setManagerSetup(PaymentManagerSetup.SET_DEFAULT);

    // Only report add_payment_method_info if user is on a checkout session.
    // Also start reporting after payment methods initialization.
    // Which initialization happens after fetch to api during component mount.
    // So, we start reporting after the first payment method change.
    // First payment method change => Initialization of data.
    if (view === PaymentMethodManagerView.CHECKOUT && paymentMethodsChangesRef.current > 1) {
      if (prevPaymentMethodsRef.current) {
        const addedMethods = paymentMethods.filter(
          (method) => !prevPaymentMethodsRef.current?.some((pm) => pm.id === method.id)
        );

        if (addedMethods.length > 0) {
          reportPurchasedProducts();
        }
      }

      // Update reference with current payment methods after each render.
      prevPaymentMethodsRef.current = paymentMethods;
    }
  }, [paymentMethods]);

  const choosePaymentMethod = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {
      setManagerSetup(PaymentManagerSetup.SET_DEFAULT);
      save(undefined, e.currentTarget.value);
    }
  };

  const addMethod = () => {
    setManagerSetup(PaymentManagerSetup.ADD);
  };
  const editMethod = () => {
    // if (!chosenPaymentMethod) {
    //   setChosenPaymentMethod(user.data.defaultPaymentMethod);
    // }
    setManagerSetup(PaymentManagerSetup.EDIT);
  };
  const deleteMethod = (methodId: string) => {
    setDisplayConfirmationModal(true);
    setRemovalPaymentMethod(methodId);
  };

  const closePaymentForm = () => {
    setManagerSetup(PaymentManagerSetup.SET_DEFAULT);
  };
  const confirmDeleteMethod = (confirmation: boolean) => {
    if (confirmation) {
      dispatch({ type: DELETE_PAYMENT_METHOD, methodId: removalPaymentMethod });
      if (paymentMethods.length === 1) {
        setManagerSetup(PaymentManagerSetup.ADD);
      }
    }
    setDisplayConfirmationModal(false);
    setRemovalPaymentMethod("");
  };

  const renderDeleteConfirmationMessage = (): string => {
    const card = paymentMethods.find((paymentMethod) => paymentMethod.id === removalPaymentMethod)
      ?.card;
    return `${card?.brand} ${card?.funding} card ending in ${card?.last4} will be deleted. You can add it back at any time, as long as it is still an active credit card.`;
  };

  const save = (paymentMethod?: PaymentMethod, chosenPaymentMethod?: string) => {
    if (paymentMethod) {
      if (managerSetup === PaymentManagerSetup.ADD) {
        dispatch({
          type: ADD_PAYMENT_METHOD,
          paymentMethodId: paymentMethod?.id,
          view,
        });
      } else if (managerSetup === PaymentManagerSetup.EDIT) {
        dispatch({
          type: UPDATE_PAYMENT_METHOD,
          paymentMethodUpdate: paymentMethod,
        });
      }
    } else {
      dispatch({
        type: SET_DEFAULT_PAYMENT_METHOD,
        methodId: chosenPaymentMethod,
        view,
      });
    }
  };

  return (
    <>
      <Card className={`manager-wrapper${cardHidden ? "-card-hidden" : ""}`}>
        {managerSetup === PaymentManagerSetup.SET_DEFAULT && (
          <>
            {paymentMethods.length > 0 && (
              <Title className="manager-title" as="h2" size="md-bold">
                Choose your preferred payment method
              </Title>
            )}

            {paymentMethods.map((paymentMethod) => (
              <PaymentContainer>
                <PaymentOption
                  id={paymentMethod.id}
                  checked={user.data.defaultPaymentMethod === paymentMethod.id}
                  value={paymentMethod.id}
                  onClick={choosePaymentMethod}
                >
                  <PaymentBody>
                    <div className="payment-body-info">
                      <div>
                        <Title as="h3" size="sm" noMarginBottom>
                          {paymentMethod.card.brand}
                        </Title>
                        <Text as="p" size="sm" noMarginBottom>
                          {`${paymentMethod.card.funding} card ending in ${paymentMethod.card.last4}`}
                        </Text>
                      </div>
                    </div>
                    <div className="payment-body-price">
                      <img src={CardLogoIndex.get(paymentMethod.card.brand)} width={45} />
                    </div>
                  </PaymentBody>
                </PaymentOption>
                {!user.isPending && view === PaymentMethodManagerView.PROFILE_SETTINGS && (
                  <Button
                    size="sm"
                    iconName="Trash"
                    isIconOnly
                    onClick={() => deleteMethod(paymentMethod.id)}
                    variant="outline"
                  />
                )}
              </PaymentContainer>
            ))}
          </>
        )}
        {paymentMethods.length > 0 && !user.isPending ? (
          <>
            {managerSetup !== PaymentManagerSetup.SET_DEFAULT ? (
              <AddEditButtonsContainer>
                <Button
                  size="md"
                  iconName="ArrowLeft"
                  onClick={closePaymentForm}
                  isIconOnly
                  variant="outline"
                />
              </AddEditButtonsContainer>
            ) : (
              <AddPaymentMethodButtonWrapper onClick={addMethod}>
                <Button size="md" iconName="Plus" isIconOnly variant="outline" />
                <Text>Add a new payment method</Text>
              </AddPaymentMethodButtonWrapper>
            )}
          </>
        ) : (
          user.isPending && (
            <div className="loader-img">
              {" "}
              <img src={Loader} />
            </div>
          )
        )}
        {managerSetup === PaymentManagerSetup.ADD && (
          <PaymentForm
            stripeHook={stripeHook}
            stripeElementsHook={stripeElementsHook}
            save={save}
            view={view}
            paymentMethods={paymentMethods}
            editablePaymentMethodId={user.data.defaultPaymentMethod}
          />
        )}
      </Card>
      <ConfirmationModal
        open={displayConfirmationModal}
        title={"Are you sure you want to delete this card?"}
        message={renderDeleteConfirmationMessage()}
        handleChoice={(confirmation: boolean) => {
          confirmDeleteMethod(confirmation);
        }}
      />
    </>
  );
};

export default PaymentMethodManager;
