import styled from "styled-components";

import { Button } from "@runwayhealth/runway-components-react";

export const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 30px 30px;
  .cart-width {
    max-width: 660px;
    width: 100%;
  }
  .start-button-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const StartButton = styled(Button)`
  margin-top: 48px;
  margin-bottom: 24px;
`;
