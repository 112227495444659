import styled from "styled-components";

import { border, from, spacing } from "@runwayhealth/runway-components-react";

export const PickerItems = styled.div<{ $upsellPage?: boolean }>`
  margin-top: ${spacing.xxs};
  margin-bottom: 6px;

  border-top: ${(props) => (props.$upsellPage ? "0px" : `${border.width.hairline} solid #e0e0e0;`)};
  text-align: left;

  .picker-items-title {
    margin: -7px auto 0 auto;
    border: 1px solid #e0e0e0;
    border-radius: 13px;
    border-width: 1px;
    box-shadow: 1px 2px #f3f3f3;
  }

  .picker-items-container {
    padding: 8px 0 2px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    @media ${from.mobile} {
      padding: 22px 0 16px 22px;
    }
  }

  .option-item {
    label {
      margin-top: 4px;
      padding-top: 15px !important;
      padding-bottom: 19px !important;
    }
    p {
      font-weight: 700;
      margin-bottom: 1px;
      margin-top: 6px;
      @media ${from.mobile} {
        margin-top: 0px;
      }
    }
  }
`;
