import styled from "styled-components";

import { color, from, Option } from "@runwayhealth/runway-components-react";

export const PaymentContainer = styled.div`
  article {
    margin-bottom: 8px;
  }
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AddPaymentMethodButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border-radius: 16px;
  border: 2px solid ${color.neutral.lighter};
  padding: 22px 24px 20px 24px;
  background: color.brand.primary.lighter;
  border-radius: 16px;
  padding: 22px 24px 20px 24px;
  margin-bottom: 10px;
  cursor: pointer;
  height: 77px;
  p {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: ${color.brand.primary.lighter};
    cursor: pointer;
    border: 2px solid ${color.brand.primary.medium};
  }
`;

export const PaymentOption = styled(Option)`
  &:hover {
    background-color: ${color.brand.primary.lighter};
    border: 2px solid ${color.brand.primary.medium};
    input:checked ~ label::before {
      background-color: #ffffff;
      border-color: #95b6fb;
    }
  }
  label {
    cursor: pointer;
  }
`;

export const PaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${from.mobileS} {
    justify-content: space-between;
    align-items: center;
  }
  .payment-body {
    &-info {
      display: flex;
      flex-direction: column;
      width: inherit;
      @media ${from.mobileS} {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 18%;
      }
    }
    &-price {
      width: fit-content;
      margin-right: 16px;
      @media (max-width: 786px) {
        margin-top: 1pc;
      }
    }
  }
`;

export const AddEditButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

export const SavePayButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
