import "./scss/description.scss";

import DOMPurify from "dompurify";
import { useEffect, useState } from "react";

import useSidebarContent from "@hooks/useSidebarContent";

import Loader from "../../../../img/Loader.svg";

type DescriptionProps = {
  productId: string;
};

const Description = ({ productId }: DescriptionProps) => {
  const { getConditionDescription } = useSidebarContent();
  const [description, setDescription] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const content = await getConditionDescription(productId);
      setDescription(DOMPurify.sanitize(content));
    })();
  }, []);

  return (
    <>
      {description ? (
        <div className="container" dangerouslySetInnerHTML={{ __html: description ?? "" }} />
      ) : (
        <div className="loader">
          {" "}
          <img className="loader-img" src={Loader} />
        </div>
      )}
    </>
  );
};

export default Description;
